import React, { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Slide,
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  DialogContent,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  Container,
  Button,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import Rating from '@material-ui/lab/Rating';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={3000} ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
  },
  content: {
    marginTop: '40px',
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'justify',
    marginTop: '10px',
    color: '#333',
    marginBottom: '15px',
    fontSize: '15px',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function DialogRating({ id, open, onClose, color, logo }) {
  const classes = useStyles();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [rating, setRating] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleCreateRating = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        await api.post('/rating', {
          rating,
          name,
          description,
          product_id: id,
        });
        addToast({
          title: 'Sucesso',
          type: 'success',
          description: 'Avalição enviada com sucesso, obrigado por avaliar!',
        });
        setRating(0);
        setName('');
        setDescription('');
        onClose();
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Houve um erro ao enviar a avalição, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [rating, name, description, addToast, id, onClose],
  );
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <>
        <AppBar
          elevation={0}
          className={classes.appBar}
          style={{ backgroundColor: `${color}` }}
        >
          <Toolbar>
            <IconButton
              name="Fechar Cupom"
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => onClose()}
            >
              <FiXCircle />
            </IconButton>
            <img src={logo} alt="Logo da empresa" width="80px" />
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent className={classes.content}>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              <Paper variant="outlined" style={{ padding: '10px' }}>
                <form onSubmit={handleCreateRating}>
                  <Container>
                    <Grid
                      container
                      spacing={4}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item xs={12} md={12}>
                        <Typography component="legend">
                          Nota do produto
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                          }}
                          precision={0.5}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          variant="outlined"
                          label="Nome(Opcional)"
                          fullWidth
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          variant="outlined"
                          label="Descrição(Opcional)"
                          multiline
                          rows="5"
                          fullWidth
                          value={description}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          fullWidth
                          className={classes.button}
                          type="submit"
                          disableElevation
                        >
                          Enviar avaliação
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </Paper>
            </>
          )}
        </DialogContent>
      </>
    </Dialog>
  );
}

DialogRating.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
