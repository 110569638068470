import React from 'react';
import PropTypes from 'prop-types';
import { AuthProvider } from './auth';
import { CompaniesProvider } from './companies';
import { ToastProvider } from './toast';

export default function AppProvider({ children }) {
  return (
    <>
      <ToastProvider>
        <AuthProvider>
          <CompaniesProvider>{children}</CompaniesProvider>
        </AuthProvider>
      </ToastProvider>
    </>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
