import axios from 'axios';

const token = localStorage.getItem('@Miru:token');

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

if (token) {
  api.defaults.headers.Authorization = `Bearer ${token}`;
}

export default api;
