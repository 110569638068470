import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  productImage: {
    marginBottom: '10px',
  },
  porductAudio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '5px',
  },
}));

export default function ProductPreview({
  audioClient,
  audioFun,
  imgProduct,
  audioClientUrl,
  audioFunUrl,
  imgProductUrl,
}) {
  const classes = useStyles();
  const previewImg = useMemo(() => {
    return imgProduct ? URL.createObjectURL(imgProduct) : null;
  }, [imgProduct]);

  const previewAudio = useMemo(() => {
    return audioClient ? URL.createObjectURL(audioClient) : null;
  }, [audioClient]);

  const previewAudioFun = useMemo(() => {
    return audioFun ? URL.createObjectURL(audioFun) : null;
  }, [audioFun]);

  return (
    <>
      <Paper
        variant="outlined"
        style={{
          padding: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className={classes.productImage}>
          {previewImg ? (
            <img
              src={previewImg}
              alt="Img product"
              width="200px"
              style={{ borderRadius: '10px' }}
            />
          ) : (
            <>
              {imgProductUrl && (
                <img
                  src={imgProductUrl}
                  alt="Img product"
                  width="200px"
                  style={{ borderRadius: '10px' }}
                />
              )}
            </>
          )}
        </div>
        <div className={classes.porductAudio}>
          {previewAudio ? (
            <>
              <audio src={previewAudio} controls />
              <span>Áudio para o cliente</span>
            </>
          ) : (
            <>
              {audioClientUrl && (
                <>
                  <audio src={audioClientUrl} controls />
                  <span>Áudio para o cliente</span>
                </>
              )}
            </>
          )}
        </div>

        <div className={classes.porductAudio}>
          {previewAudioFun ? (
            <>
              <audio src={previewAudioFun} controls />
              <span>Áudio para o funcionário</span>
            </>
          ) : (
            <>
              {audioFunUrl && (
                <>
                  <audio src={audioFunUrl} controls />
                  <span>Áudio para o funcionário</span>
                </>
              )}
            </>
          )}
        </div>
      </Paper>
    </>
  );
}

ProductPreview.propTypes = {
  audioClient: PropTypes.objectOf.isRequired,
  audioFun: PropTypes.objectOf.isRequired,
  imgProduct: PropTypes.objectOf.isRequired,
  audioClientUrl: PropTypes.string.isRequired,
  audioFunUrl: PropTypes.string.isRequired,
  imgProductUrl: PropTypes.string.isRequired,
};
