import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { shade } from 'polished';
import {
  Grid,
  TextField,
  Fab,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  makeStyles,
  Button,
} from '@material-ui/core';
import { FiPlusCircle, FiXCircle, FiCheckCircle } from 'react-icons/fi';

import { useCompany } from '../../../hooks/companies';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  buttonError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.error.main)} !important`,
      color: '#fff',
    },
  },

  buttonActive: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',

    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.success.main)} !important`,
      color: '#fff',
    },
  },

  input: {
    display: 'none',
  },

  paperUpload: {
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  avatar: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Register() {
  const classes = useStyles();
  const history = useHistory();

  const { userId } = useParams();
  const { company } = useCompany();
  const { user } = useAuth();
  const { addToast } = useToast();

  const [updateList, setUpdateList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorsField, setErrorsField] = useState([]);

  const [login, setLogin] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [avatar, setAvatar] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [active, setActive] = useState(true);
  const [companyId, setCompanyId] = useState(() => {
    if (company?.id && user.role === 'owner') {
      return company?.id;
    }
    return '';
  });
  const previewAvatar = useMemo(() => {
    return avatar ? URL.createObjectURL(avatar) : null;
  }, [avatar]);

  useEffect(() => {
    async function loadProfile() {
      setIsLoading(true);
      try {
        if (userId) {
          setIsUpdate(true);
          const response = await api.get(`/user/show/${userId}`);
          setLogin(response.data.user.login);
          setActive(response.data.user.active);
          setName(response.data.user.name);
          if (response.data.user.avatar) {
            setAvatarUrl(response.data.user.avatarUrl);
          }
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao buscar o usuário, tente novamente!',
        });
      } finally {
        setIsLoading(false);
        setUpdateList(false);
      }
    }

    loadProfile();
  }, [userId, updateList, addToast]);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = new FormData();
        data.append('name', name);
        data.append('login', login);
        data.append('password', password);
        data.append('confirmPassword', confirmPassword);
        data.append('role', user.role === 'admin' ? 'owner' : 'user');
        data.append('active', true);
        data.append('company_id', companyId);
        data.append('avatar', avatar);

        await api.post('/users', data);
        history.push('/user/list');
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Usuário adicionado com sucesso!',
        });
      } catch (error) {
        if (error.response.data.messages) {
          setErrorsField(error.response.data.messages);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao adicionar o usuário, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      avatar,
      companyId,
      login,
      password,
      confirmPassword,
      user.role,
      history,
      addToast,
    ],
  );

  const handleUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = new FormData();
        data.append('name', name);
        data.append('login', login);
        data.append('password', password);
        data.append('confirmPassword', confirmPassword);
        data.append('active', true);
        data.append('avatar', avatar);

        await api.put(`/users/${userId}`, data);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Usuário atualizado com sucesso!',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            'Houve um erro ao atualizado o usuário, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [name, login, password, confirmPassword, userId, addToast, avatar],
  );

  const handleStatusUser = useCallback(async () => {
    try {
      await api.delete(`/users/${userId}`);
      addToast({
        title: 'Alerta',
        type: 'warning',
        description: 'Status alterado, com sucesso!',
      });
      setUpdateList(true);
    } catch (err) {
      addToast({
        title: 'Error',
        type: 'error',
        description:
          'Houve um erro ao alterar o status da empresa, tente novamente!',
      });
    }
  }, [userId, addToast]);

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <form onSubmit={isUpdate ? handleUpdate : handleRegister}>
          <Grid container direction="row" spacing={4}>
            <Grid item md={4} sm={12}>
              <Grid container spacing={4} alignItems="center">
                <Grid item md={12} sm={12} xs={12}>
                  <Paper variant="outlined" className={classes.paperUpload}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      onChange={(event) => setAvatar(event.target.files[0])}
                      type="file"
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                      >
                        Adicionar Avatar
                      </Button>
                    </label>
                    <div style={{ marginTop: '20px' }}>
                      {previewAvatar && (
                        <img
                          src={previewAvatar}
                          alt="Novo avatar"
                          className={classes.avatar}
                        />
                      )}
                      {avatarUrl && (
                        <img
                          src={avatarUrl}
                          alt="Novo avatar"
                          className={classes.avatar}
                        />
                      )}
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Fab
                    fullWidth
                    variant="extended"
                    color="primary"
                    size="medium"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                    type="submit"
                  >
                    <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
                    {!isUpdate ? 'Adicionar' : 'Atualizar'}
                  </Fab>
                  {user.role !== 'user' && (
                    <Fab
                      fullWidth
                      variant="extended"
                      color="primary"
                      size="medium"
                      className={`${
                        !active
                          ? `${classes.buttonActive}`
                          : `${classes.buttonError}`
                      }`}
                      onClick={() => handleStatusUser()}
                    >
                      {!active ? (
                        <FiCheckCircle
                          size={20}
                          style={{ marginRight: '10px' }}
                        />
                      ) : (
                        <FiXCircle size={20} style={{ marginRight: '10px' }} />
                      )}
                      {!active ? 'Ativar' : 'Desativar'}
                    </Fab>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Paper variant="outlined" style={{ padding: '30px' }}>
                <div style={{ padding: '10px' }}>
                  <Typography variant="body1">Adicionar Usuário</Typography>
                </div>
                <Divider />
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete={false}
                      variant="outlined"
                      margin="normal"
                      label="Nome"
                      type="text"
                      fullWidth
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      error={errorsField.find((data) => data.path === 'name')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete={false}
                      variant="outlined"
                      margin="normal"
                      label="Usuário"
                      type="text"
                      fullWidth
                      value={login}
                      onChange={(event) => {
                        setLogin(event.target.value);
                      }}
                      error={errorsField.find((data) => data.path === 'login')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete={false}
                      variant="outlined"
                      margin="normal"
                      label="Senha"
                      type="password"
                      fullWidth
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      error={errorsField.find(
                        (data) => data.path === 'password',
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete={false}
                      variant="outlined"
                      margin="normal"
                      label="Confirmar Senha"
                      type="password"
                      fullWidth
                      value={confirmPassword}
                      onChange={(event) => {
                        setConfirmPassword(event.target.value);
                      }}
                      error={errorsField.find(
                        (data) => data.path === 'confirmPassword',
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
