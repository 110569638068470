import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

function Route({ isPrivate, component: Component, ...rest }) {
  const { logged } = useAuth();

  if (!logged && isPrivate) {
    return <Redirect to="/" />;
  }

  if (logged && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <ReactRouterDOMRoute
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
}
Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

Route.defaultProps = {
  isPrivate: false,
};

export default Route;
