import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/global';

import Routes from './routes';
import AppProvider from './hooks';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#9c13aa' },
    secondary: { main: '#834bff' },
    error: { main: '#c53030' },
    warning: { main: '#ff9000' },
    success: { main: '#2e656a' },
    info: { main: '#3172b7' },
  },
  overrides: {
    MuiDrawer: {
      root: {
        overflow: 'visible',
      },
    },
  },
});

function App() {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
