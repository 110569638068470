import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Miru:token');
    const user = localStorage.getItem('@Miru:user');

    if (token && user) {
      return { token, user: JSON.parse(user), logged: true };
    }
    return {};
  });

  const signIn = useCallback(async ({ login, password }) => {
    const response = await api.post('/session', { login, password });

    const { token, user } = response.data;

    localStorage.setItem('@Miru:token', token);
    localStorage.setItem('@Miru:user', JSON.stringify(user));

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setData({ token, user, logged: true });
  }, []);

  const singOut = useCallback(() => {
    localStorage.removeItem('@Miru:token');
    localStorage.removeItem('@Miru:user');
    localStorage.removeItem('@Miru:company');
    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, logged: data.logged, signIn, singOut }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthProvider, useAuth };
