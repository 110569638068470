import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'clsx';
import {
  Grid,
  Slide,
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  DialogContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';

import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    height: '450px',
    maxHeight: '100%',
    borderRadius: 6,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  name: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'justify',
    marginTop: '10px',
    color: '#333',
    marginBottom: '15px',
    fontSize: '15px',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  loading: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function DialogCoupon({ open, onClose, id, color, logo }) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const shadowStyles = useOverShadowStyles({ inactive: true });

  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [coupon, setCoupon] = useState('');

  useEffect(() => {
    async function loadCoupon() {
      try {
        const response = await api.get(`coupons/show/${id}`);
        setCoupon(response.data.response);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao carregar o produto, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }
    if (id) {
      loadCoupon();
    }
  }, [addToast, id]);
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        {isLoading ? (
          <div className={styles.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <AppBar
              className={styles.appBar}
              elevation={0}
              style={{ backgroundColor: `${color}` }}
            >
              <Toolbar>
                <IconButton
                  name="Fechar Cupom"
                  edge="start"
                  color="inherit"
                  onClick={() => onClose()}
                  aria-label="close"
                >
                  <FiXCircle />
                </IconButton>
                <img src={logo} alt="Logo da empresa" width="80px" />
              </Toolbar>
              <Divider />
            </AppBar>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card className={cx(styles.root, shadowStyles.root)}>
                    <CardContent className={styles.content}>
                      <Typography className={styles.text}>
                        Desconto: {coupon.cuopon}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Link to={`/products/${coupon.refs?.product_hash}`}>
                    <Card className={cx(styles.root, shadowStyles.root)}>
                      <CardMedia
                        className={cx(styles.media, mediaStyles.root)}
                        image={coupon.refs?.image_url}
                      />
                      <CardContent className={styles.content}>
                        <Typography className={styles.name}>
                          {coupon.refs?.name}
                        </Typography>
                        <Divider />
                        <Typography className={styles.description}>
                          {coupon.refs?.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
}

DialogCoupon.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
