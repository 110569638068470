import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Paper,
  IconButton,
  List as ListMaterial,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  CircularProgress,
  makeStyles,
  Typography,
  Grid,
  Select,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FiHome, FiPlusCircle } from 'react-icons/fi';

import { useAuth } from '../../../hooks/auth';
import { useCompany } from '../../../hooks/companies';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: `${palette.success.main}`,
    fontWeight: 'bold',
  },
  disabled: {
    color: `${palette.error.main}`,
    fontWeight: 'bold',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function List() {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);

  const { user } = useAuth();
  const history = useHistory();
  const { addCompany } = useCompany();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadCompanies() {
      try {
        const response = await api.get(
          `/companies/${user.id}?page=${page}&pageSize=${pageSize}&role=${user.role}`,
        );
        setCompanies(response.data.companies.rows);
        setCountPages(response.data.companies.count);
        setIsLoading(false);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um error os buscar as empresas, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadCompanies();
  }, [user.id, user.role, addToast, page, pageSize]);

  const defineCompany = (company) => {
    if (!company.active) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Empresa está desativada, entre em contato com o suporte!',
      });
    } else {
      history.push('/dashboard');
      addCompany(company);
    }
  };

  const goToProfileCompany = (company) => {
    if (company.active) {
      history.push(`/company/${company.id}`);
    } else {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Empresa está desativada, entre em contato com o suporte!',
      });
      history.push(`/dashboard`);
    }
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <Fab
              variant="extended"
              size="large"
              color="primary"
              aria-label="add"
              style={{ fontWeight: 'bold' }}
              onClick={() => history.push('/company')}
            >
              <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
              Nova Empresa
            </Fab>
            <ListMaterial>
              {companies.map((company) => (
                <div key={company.id}>
                  <Paper
                    variant="outlined"
                    style={{ padding: '20px', marginBottom: '20px' }}
                  >
                    <ListItem
                      button
                      onClick={() => goToProfileCompany(company)}
                    >
                      <ListItemText
                        primary={company.name}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${
                                company.active
                                  ? `${classes.active}`
                                  : `${classes.disabled}`
                              }`}
                            >
                              {company.active ? 'Ativado' : 'Desativado'}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => defineCompany(company)}
                          color="primary"
                        >
                          <FiHome size={20} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Paper>
                </div>
              ))}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Pagination
                  count={Math.ceil(countPages / pageSize)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                  color="primary"
                />
                <Select
                  native
                  value={pageSize}
                  onChange={(event) => setPageSize(event.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                </Select>
              </Grid>
            </ListMaterial>
          </>
        )}
      </Container>
    </>
  );
}
