import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { useCompany } from '../../hooks/companies';

import Header from '../../components/Header';
import SideNav from '../../components/Sidenav';
import DialogCompanies from '../../components/DialogCompanies';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1520px',
    margin: '0 auto',
    padding: '0 20px 100px',
    marginTop: '60px',

    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  scroll: {
    overflow: 'visible !important',
  },
}));

function Dashboard({ children }) {
  const classes = useStyles();
  const { selectedCompany } = useCompany();

  const [openNav, setOpenNav] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);

  useEffect(() => {
    if (selectedCompany) {
      setOpenDialog(false);
    }
  }, [selectedCompany]);

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Header onToggleNav={toggleNav} onToggleDialog={toggleDialog} />
      <SideNav open={openNav} onToggleNav={toggleNav} />
      <main>
        <div className={classes.container}>{children}</div>
        <DialogCompanies open={openDialog} onToggleDialog={toggleDialog} />
      </main>
    </>
  );
}

Dashboard.propTypes = {
  children: PropTypes.arrayOf.isRequired,
};

export default withRouter(Dashboard);
