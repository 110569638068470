import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Dashboard from '../layouts/Dashboard';

import Login from '../pages/Login';
import FormUser from '../pages/Users/Register';
import ListUser from '../pages/Users/List';
import FormProduct from '../pages/Products/Form';
import ListProduct from '../pages/Products/List';
import InfoProduct from '../pages/Products/Details';
import DetailsEmployee from '../pages/Products/DetailsEmployee';
import ListCompany from '../pages/Company/List';
import FormCompany from '../pages/Company/Form';
import FormCuopon from '../pages/Coupon/Form';
import ListCuopon from '../pages/Coupon/List';
import FormCategories from '../pages/Categories/Form';
import ListCategories from '../pages/Categories/List';
import PrivacyMobile from '../pages/Privacy/Mobile';

const Rotues = () => {
  return (
    <Switch>
      <Route component={InfoProduct} path="/products/:id?" />
      <Route component={Login} path="/" exact />
      <Route component={PrivacyMobile} path="/privacy/mobile" />
      <Route component={DetailsEmployee} path="/details/:id" isPrivate />
      <Dashboard>
        <Route component={FormUser} path="/users/:userId?" isPrivate />
        <Route component={ListUser} path="/user/list" isPrivate />
        <Route component={FormProduct} path="/product/:productId?" isPrivate />
        <Route component={ListProduct} path="/dashboard" isPrivate />
        <Route component={ListCompany} exact path="/companies/list" isPrivate />
        <Route component={FormCompany} path="/company/:companyId?" isPrivate />
        <Route
          component={ListCuopon}
          path="/coupon/list/:productId"
          isPrivate
        />
        <Route
          component={FormCuopon}
          path="/coupon/form/:couponId?"
          isPrivate
        />
        <Route
          component={FormCategories}
          path="/categories/:categoriesId?"
          isPrivate
        />
        <Route
          component={ListCategories}
          path="/list/categories"
          exact
          isPrivate
        />
      </Dashboard>
    </Switch>
  );
};

export default Rotues;
