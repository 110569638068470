import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Hidden,
} from '@material-ui/core';
import { FiMenu, FiLogOut, FiHome, FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { useCompany } from '../../hooks/companies';

import logoImg from '../../assets/logo-miru-black.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuActions: {
    maxWidth: '300px',
  },
  userName: {
    fontSize: '15px',
  },
  companyName: {
    fontSize: '13px',
    color: '#333',
  },
  logo: {
    margin: '0 30px',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

export default function Header({ onToggleNav, onToggleDialog }) {
  const classes = useStyles();
  const history = useHistory();

  const { user, singOut } = useAuth();
  const { company, removeCompany } = useCompany();

  const handleLogout = () => {
    singOut();
    removeCompany();
    history.push('/');
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} color="inherit">
        <Toolbar>
          <Tooltip title="Abrir menu" aria-label="add">
            <IconButton color="inherit" onClick={() => onToggleNav()}>
              <FiMenu size={22} />
            </IconButton>
          </Tooltip>
          {history.location.pathname !== '/dashboard' && (
            <Tooltip title="Pagína anterior" aria-label="Pagína anterior">
              <IconButton onClick={() => history.goBack()} color="inherit">
                <FiArrowLeft />
              </IconButton>
            </Tooltip>
          )}

          <Link to="/dashboard" label="Home">
            <img
              src={logoImg}
              alt="Logo"
              width="150px"
              className={classes.logo}
            />
          </Link>

          <div className={classes.title} />
          <Hidden smDown>
            <Tooltip title="Acessar perfil" aria-label="Acessar perfil">
              <Link to={`/users/${user.id}`}>
                <Avatar src={user.avatarUrl} />
              </Link>
            </Tooltip>
            <div style={{ marginLeft: '10px' }}>
              <Typography className={classes.userName}>
                Olá, {user.name}
              </Typography>
              <Typography className={classes.companyName}>
                {!!company && `Acessado: ${company.name}`}
              </Typography>
            </div>
            <Tooltip title="Acessar empresa" aria-label="Acessar empresa">
              <IconButton
                color="inherit"
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={() => onToggleDialog()}
              >
                <FiHome size={22} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sair do app" aria-label="add">
              <IconButton color="inherit" onClick={() => handleLogout()}>
                <FiLogOut size={22} />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Divider />
    </div>
  );
}

Header.propTypes = {
  onToggleNav: PropTypes.func.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
};
