import React, { useState, useEffect, useCallback } from 'react';
import { shade } from 'polished';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  TextField,
  Paper,
  Fab,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  FiPlusCircle,
  FiCheckCircle,
  FiXCircle,
  FiTag,
  FiStar,
} from 'react-icons/fi';
import AutoComplete from '@material-ui/lab/Autocomplete';

import { useCompany } from '../../../hooks/companies';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import ProductPreview from './ProductPreview';
import ListRating from './ListRating';

const useStyles = makeStyles((theme) => ({
  buttonError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.error.main)} !important`,
      color: '#fff',
    },
  },
  buttonActive: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.success.main)} !important`,
      color: '#fff',
    },
  },

  input: {
    display: 'none',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default function Form() {
  const classes = useStyles();
  const history = useHistory();

  const { productId } = useParams();
  const { company } = useCompany();
  const { user } = useAuth();
  const { addToast } = useToast();

  const [openDialogRating, setOpenDialogRating] = useState(false);
  const [listCategories, setListCategories] = useState([]);
  const [category, setCategory] = useState([]);

  const [audioUrl, setAudioUrl] = useState('');
  const [audioFunUrl, setAudioFunUrl] = useState('');
  const [imgProductUrl, setImgProductUrl] = useState('');

  const [isUpdate, setIsUpdate] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [errorsField, setErrorsField] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [linkClient, setLinkClient] = useState('');
  const [audio, setAudio] = useState(null);
  const [audioFun, setAudioFun] = useState(null);
  const [imgProduct, setImgProduct] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get(`/categories/filter/${company.id}`);
        const categoriesWithFilter = response.data.categories.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setListCategories(categoriesWithFilter);
      } catch (err) {
        addToast({
          title: 'Erro',
          type: 'error',
          description:
            'Houve um erro ao carregar as categorias, tente novamente!',
        });
      }
    }

    loadCategories();
  }, [company, addToast]);

  useEffect(() => {
    async function loadEditProduct() {
      try {
        setIsUpdate(true);
        const response = await api.get(`edit/products/${productId}`);
        setName(response.data.product.name);
        setDescription(response.data.product.description);
        setLink(response.data.product.video);
        setLinkClient(response.data.product.video_client);
        setAudioUrl(response.data.product.audio_url);
        setAudioFunUrl(response.data.product.fun_audio_url);
        setImgProductUrl(response.data.product.image_url);
        setActive(response.data.product.active);
        const selectedcategories = response.data.product.categories.map(
          (item) => {
            return {
              label: item.name,
              value: item.id,
            };
          },
        );
        setCategory(selectedcategories);
      } catch (err) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Houve um erro ao carregar o produto, tente novamente!',
        });
      }
    }
    if (productId) {
      loadEditProduct();
    }
  }, [productId, updateForm, addToast]);

  const handleCreateProduct = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const arrayCatageroies = category.map((item) => {
          return item.value;
        });
        const data = new FormData();
        data.append('name', name);
        data.append('description', description);
        data.append('video', link);
        data.append('video_client', linkClient);
        data.append('audio', audio);
        data.append('fun_audio', audioFun);
        data.append('image', imgProduct);
        data.append('category_id', arrayCatageroies);
        data.append('user_id', user.id);
        data.append('company_id', company.id);

        await api.post('/products', data);
        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Produto adicionado com sucesso!',
        });
        history.push('/dashboard');
      } catch (error) {
        if (error.response?.data.messages) {
          setErrorsField(error.response.data.messages);
          return;
        }
        addToast({
          title: 'Erro!',
          type: 'error',
          description: 'Houve um erro ao adicionar o produto, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      company.id,
      user.id,
      history,
      name,
      description,
      link,
      linkClient,
      audioFun,
      audio,
      imgProduct,
      addToast,
      category,
    ],
  );

  const handleUpdateProduct = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const arrayCatageroies = [];

        for (let index = 0; index < category.length; index++) {
          const item = category[index].value;
          arrayCatageroies.push(item);
        }

        const data = new FormData();
        data.append('name', name);
        data.append('description', description);
        data.append('video', link);
        data.append('video_client', linkClient);
        data.append('audio', audio);
        data.append('fun_audio', audioFun);
        data.append('image', imgProduct);
        data.append('category_id', JSON.stringify(arrayCatageroies));
        data.append('user_id', user.id);
        data.append('company_id', company.id);

        await api.put(`/products/${productId}`, data);
        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Produto atualizado com sucesso!',
        });
      } catch (error) {
        setErrorsField(error.response);
        addToast({
          title: 'Erro!',
          type: 'error',
          description: 'Houve um erro ao atualizar o produto, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      description,
      link,
      linkClient,
      audioFun,
      audio,
      imgProduct,
      category,
      addToast,
      company.id,
      user.id,
      productId,
    ],
  );

  const handleStatusProduct = useCallback(async () => {
    setUpdateForm(false);
    try {
      await api.delete(`/products/${productId}`);
      addToast({
        title: 'Alerta',
        type: 'warning',
        description: 'Status alterado, com sucesso!',
      });
      setUpdateForm(true);
    } catch (err) {
      addToast({
        title: 'Error',
        type: 'error',
        description:
          'Houve um erro ao alterar o status da empresa, tente novamente!',
      });
    }
  }, [productId, addToast]);

  const handleOpenDialogRating = useCallback(() => {
    setOpenDialogRating(!openDialogRating);
  }, [openDialogRating]);

  return (
    <Container>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <ProductPreview
                audioClient={audio}
                audioFun={audioFun}
                imgProduct={imgProduct}
                audioClientUrl={audioUrl}
                audioFunUrl={audioFunUrl}
                imgProductUrl={imgProductUrl}
              />
              {isUpdate && (
                <div
                  style={{
                    maxWidth: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Fab
                    fullWidth
                    variant="extended"
                    color="secondary"
                    size="medium"
                    style={{ fontWeight: 'bold', marginLeft: '10px' }}
                    onClick={handleOpenDialogRating}
                  >
                    <FiStar size={20} style={{ marginRight: '10px' }} />
                    Visualizar avaliações
                  </Fab>
                  <ListRating
                    id={productId}
                    open={openDialogRating}
                    onClose={handleOpenDialogRating}
                  />
                </div>
              )}
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={2} style={{ marginBottom: '10px' }} />
              <Paper variant="outlined" style={{ padding: '30px' }}>
                <form
                  onSubmit={
                    !isUpdate ? handleCreateProduct : handleUpdateProduct
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="imgProduct"
                        onChange={(event) =>
                          setImgProduct(event.target.files[0])
                        }
                        type="file"
                      />

                      <label htmlFor="imgProduct">
                        <Button
                          variant="outlined"
                          color="secondary"
                          component="span"
                          fullWidth
                          size="small"
                        >
                          Imagem do produto
                        </Button>
                      </label>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <input
                        className={classes.input}
                        id="AudioClient"
                        onChange={(event) => setAudio(event.target.files[0])}
                        type="file"
                      />

                      <label htmlFor="AudioClient">
                        <Button
                          variant="outlined"
                          color="secondary"
                          component="span"
                          fullWidth
                          size="small"
                        >
                          Audio para o cliente
                        </Button>
                      </label>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <input
                        className={classes.input}
                        id="AudioFun"
                        onChange={(event) => setAudioFun(event.target.files[0])}
                        type="file"
                      />

                      <label htmlFor="AudioFun">
                        <Button
                          variant="outlined"
                          color="secondary"
                          component="span"
                          fullWidth
                          size="small"
                        >
                          Audio para o funcionário
                        </Button>
                      </label>
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        autoCompete={false}
                        variant="outlined"
                        fullWidth
                        label="Produto"
                        margin="normal"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={errorsField?.find(
                          (data) => data.path === 'name',
                        )}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        autoCompete={false}
                        variant="outlined"
                        fullWidth
                        label="Descrição"
                        margin="normal"
                        multiline
                        rows="5"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'description',
                        )}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <AutoComplete
                        multiple
                        options={listCategories}
                        value={category}
                        disableClearable
                        color="primary"
                        onChange={(event, value) => setCategory(value)}
                        getOptionLabel={(option) => option.label}
                        style={{ width: '100%' }}
                        getOptionSelected={(option, value) => {
                          return option.label === value.label;
                        }}
                        noOptionsText="Responsável não encontrado"
                        renderInput={(params) => (
                          <TextField
                            autoCompete={false}
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Buscar categorias"
                            margin="normal"
                            error={errorsField.find(
                              (data) => data.path === 'category_id',
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        autoCompete={false}
                        variant="outlined"
                        fullWidth
                        label="Video para o funcionário"
                        margin="normal"
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'video',
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        autoCompete={false}
                        variant="outlined"
                        fullWidth
                        label="Video para o cliente"
                        margin="normal"
                        value={linkClient}
                        onChange={(event) => setLinkClient(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'video_client',
                        )}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Fab
                        fullWidth
                        variant="extended"
                        color="primary"
                        size="medium"
                        style={{ fontWeight: 'bold' }}
                        type="submit"
                      >
                        <FiPlusCircle
                          size={20}
                          style={{ marginRight: '10px' }}
                        />
                        {!isUpdate ? 'Adicionar' : 'Atualizar'}
                      </Fab>
                      {isUpdate && (
                        <>
                          <Fab
                            fullWidth
                            variant="extended"
                            color="secondary"
                            size="medium"
                            style={{ fontWeight: 'bold', marginLeft: '10px' }}
                            onClick={() =>
                              history.push(`/coupon/list/${productId}`)
                            }
                          >
                            <FiTag size={20} style={{ marginRight: '10px' }} />
                            Adicionar Cupom
                          </Fab>

                          <Fab
                            fullWidth
                            variant="extended"
                            color="primary"
                            size="medium"
                            className={`${
                              !active
                                ? `${classes.buttonActive}`
                                : `${classes.buttonError}`
                            }`}
                            onClick={() => handleStatusProduct()}
                          >
                            {!active ? (
                              <FiCheckCircle
                                size={20}
                                style={{ marginRight: '10px' }}
                              />
                            ) : (
                              <FiXCircle
                                size={20}
                                style={{ marginRight: '10px' }}
                              />
                            )}
                            {!active ? 'Ativar' : 'Desativar'}
                          </Fab>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
