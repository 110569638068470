import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  TextField,
  Fab,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { FiPlusCircle } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';
import { useCompany } from '../../../hooks/companies';

import api from '../../../services/api';

export default function Form() {
  const { company } = useCompany();
  const { addToast } = useToast();
  const { couponId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [isUpdate, setIsUpdate] = useState(false);

  const [coupon, setCoupon] = useState('');
  const [productId, setProductId] = useState('');
  const [productRef, setProductRef] = useState('');
  const [errorsField, setErrorsField] = useState([]);

  const [optionsProducts, setOptionsProducts] = useState([]);

  useEffect(() => {
    async function loadProduct() {
      try {
        const response = await api.get(
          `/edit/products/${location.state.productId}`,
        );

        setProductId({
          label: response.data.product.name,
          value: response.data.product.id,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao carregar o produto, tente novamente!',
        });
      }
    }
    loadProduct();
  }, []);

  useEffect(() => {
    async function loadAllProducts() {
      try {
        const response = await api.get(`/all/${company.id}`);
        const products = response.data.products.map((product) => {
          return {
            label: product.name,
            value: product.id,
          };
        });

        setOptionsProducts(products);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            'Houve um erro ao carregar os produtos, tente novamente!',
        });
      }
    }
    loadAllProducts();
  }, [company, addToast]);

  useEffect(() => {
    async function loadEditCoupon() {
      setIsUpdate(true);
      try {
        const response = await api.get(`/cuopons/show/edit/${couponId}`);
        setCoupon(response.data.response.cuopon);
        setProductId({
          label: response.data.response.products.name,
          value: response.data.response.products.id,
        });
        setProductRef({
          label: response.data.response.refs.name,
          value: response.data.response.refs.id,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            'Houve um erro ao carregar os produtos, tente novamente!',
        });
      }
    }
    if (couponId) {
      loadEditCoupon();
    }
  }, [couponId, addToast]);

  const handleCreateCoupon = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await api.post('/cuopons', {
          product_id: productId.value,
          product_ref: productRef.value,
          cuopon: coupon,
        });
        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Coupom adcionando com sucesso!',
        });
        history.push(`/coupon/list/${productId.value}`);
      } catch (error) {
        if (error?.response.data.messages) {
          setErrorsField(error.response.data.messages);
          return;
        }
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Houve um erro ao cadastrar o cupom, tente novamente!',
        });
      }
    },
    [productId, productRef, coupon, addToast, history],
  );

  const handleUpdateCoupon = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await api.put(`/cuopons/${couponId}`, {
          product_id: productId.value,
          product_ref: productRef.value,
          coupon,
        });
        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Coupom atualizado com sucesso!',
        });
      } catch (err) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Houve um erro ao cadastrar o cupom, tente novamente!',
        });
      }
    },
    [productId, productRef, coupon, addToast, couponId],
  );

  return (
    <form onSubmit={!isUpdate ? handleCreateCoupon : handleUpdateCoupon}>
      <Grid container direction="row" spacing={4}>
        <Grid item md={10} sm={12}>
          <Paper variant="outlined" style={{ padding: '30px' }}>
            <div style={{ padding: '10px' }}>
              <Typography variant="body1">Adicionar Cupom</Typography>
            </div>
            <Divider />
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  autoComplete={false}
                  margin="normal"
                  label="Cupom"
                  type="text"
                  fullWidth
                  value={coupon}
                  onChange={(event) => {
                    setCoupon(event.target.value);
                  }}
                  error={errorsField.find((data) => data.path === 'coupon')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoComplete
                  options={optionsProducts}
                  disableClearable
                  value={productId}
                  onChange={(event, value) => {
                    setProductId(value);
                  }}
                  getOptionLabel={(option) => option.label}
                  style={{ width: '100%' }}
                  getOptionSelected={(option, value) => {
                    return option.label === value.label;
                  }}
                  noOptionsText="Produto não encontrado"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      autoComplete={false}
                      variant="outlined"
                      label="Produto principal"
                      margin="normal"
                      error={errorsField.find(
                        (data) => data.path === 'product_id',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoComplete
                  options={optionsProducts}
                  disableClearable
                  value={productRef}
                  onChange={(event, value) => {
                    setProductRef(value);
                  }}
                  getOptionLabel={(option) => option.label}
                  style={{ width: '100%' }}
                  getOptionSelected={(option, value) => {
                    return option.label === value.label;
                  }}
                  noOptionsText="Produto não encontrado"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete={false}
                      fullWidth
                      variant="outlined"
                      label="Produto referência"
                      margin="normal"
                      error={errorsField.find(
                        (data) => data.path === 'product_ref',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <Fab
                  fullWidth
                  variant="extended"
                  color="primary"
                  size="medium"
                  style={{ fontWeight: 'bold' }}
                  type="submit"
                >
                  <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
                  {!isUpdate ? 'Adicionar' : 'Atualizar'}
                </Fab>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
