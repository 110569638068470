import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Snackbar, makeStyles, IconButton, Slide } from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';

import { useToast } from '../../hooks/toast';

const useStyles = makeStyles({
  container: `
    position: relative;
    & + div {
      margin-top: 8px;
    }
  `,
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function Toast({ messages }) {
  const classes = useStyles();
  const { removeToast } = useToast();
  return (
    <>
      {messages.map((item) => (
        <Snackbar
          key={item.id}
          autoHideDuration={6000}
          open={item.open}
          onClose={() => removeToast(item.id)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.container}
          TransitionComponent={SlideTransition}
        >
          <Alert
            severity={item.type}
            variant="filled"
            elevation={6}
            style={{ width: '100%' }}
            action={
              <IconButton onClick={() => removeToast(item.id)}>
                <FiXCircle color="#fff" />
              </IconButton>
            }
          >
            <AlertTitle>{item.title}</AlertTitle>
            <strong>{item.description}</strong>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
}

Toast.propTypes = {
  messages: PropTypes.instanceOf(Object).isRequired,
};
