import React, { useState } from 'react';
import { FiUser, FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Typography,
  CircularProgress,
  Fade,
  makeStyles,
  Hidden,
} from '@material-ui/core';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import logo from '../../assets/logo-miru-black.png';

import { Container, Content, Form, Logo, LogoContainer } from './styles';

const useStyles = makeStyles(() => ({
  button: {
    height: '50px',
    fontWeight: 'bold',
  },
  loading: {
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Login() {
  const classes = useStyles();
  const fadeTransiction = true;

  const { signIn } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isloading, setIsloading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsloading(true);
    try {
      await signIn({ login, password });
      history.push('/dashboard');
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Bem-vindo ao Miru!',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no login',
        description: 'Login ou Senha são invalidos!',
      });
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Grid container>
      <Grid item md={6} sm={12} xs={12} alignItems="center" justify="center">
        <Fade in={fadeTransiction} mountOnEnter unmountOnExi timeout={1000}>
          <Container style={{ background: '#f0f0f5' }}>
            <Content>
              {isloading ? (
                <div className={classes.loading}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <div>
                  <Hidden mdUp>
                    <LogoContainer>
                      <Logo src={logo} alt="logo" />
                    </LogoContainer>
                  </Hidden>
                  <Form onSubmit={handleLogin}>
                    <Typography color="inherit" variant="h5" align="center">
                      Faça seu login
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete={false}
                          variant="outlined"
                          margin="normal"
                          type="text"
                          label="Usuário"
                          value={login}
                          onChange={(event) => {
                            setLogin(event.target.value);
                          }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiUser size={20} />
                              </InputAdornment>
                            ),
                          }}
                          autoFocus
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          autoComplete={false}
                          variant="outlined"
                          margin="normal"
                          type="password"
                          label="Senha"
                          fullWidth
                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiLock size={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          fullWidth
                          className={classes.button}
                        >
                          Avançar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </div>
              )}
            </Content>
          </Container>
        </Fade>
      </Grid>
      <Hidden smDown>
        <Grid item md={6}>
          <Fade in={fadeTransiction} mountOnEnter unmountOnExi timeout={1000}>
            <Container>
              <Content>
                <LogoContainer>
                  <Logo src={logo} alt="logo" />
                </LogoContainer>
                <Typography color="inherit" variant="h5" align="center">
                  Divulgue os seus melhores produtos!
                </Typography>
              </Content>
            </Container>
          </Fade>
        </Grid>
      </Hidden>
    </Grid>
  );
}
