import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { shade } from 'polished';
import {
  Grid,
  TextField,
  Fab,
  Paper,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { FiPlusCircle, FiXCircle, FiCheckCircle } from 'react-icons/fi';
import { SketchPicker } from 'react-color';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  buttonError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.error.main)} !important`,
      color: '#fff',
    },
  },
  ButtonActive: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: `${shade(0.2, theme.palette.success.main)} !important`,
      color: '#fff',
    },
  },

  input: {
    display: 'none',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Form() {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useAuth();
  const { addToast } = useToast();
  const { companyId } = useParams();

  const [updateList, setUpdateList] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [ownersList, setOwnersList] = useState([]);

  const [owner, setOwner] = useState('');
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [color, setColor] = useState('#9c13aa');
  const [link, setLink] = useState('');
  const [active, setActive] = useState(true);
  const [logoUrl, setLogoUrl] = useState('');
  const [customButton, setCustomButton] = useState('');
  const [errorsField, setErrorsField] = useState([]);

  const previewImg = useMemo(() => {
    return logo ? URL.createObjectURL(logo) : null;
  }, [logo]);

  useEffect(() => {
    async function loadOwners() {
      try {
        const response = await api.get(`/owners`);

        const owners = response.data.users.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setOwnersList(owners);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Houve um erro ao buscar os responsável da empresa, tente novamente!',
        });
      } finally {
        setUpdateList(false);
      }
    }

    loadOwners();
  }, [user, addToast]);

  useEffect(() => {
    async function loadCompany() {
      try {
        if (companyId) {
          setIsLoading(true);
          setIsUpdate(true);
          const response = await api.get(`/companies/show/${companyId}`);
          setName(response.data.company.name);
          setCnpj(response.data.company.cnpj);
          setTelephone(response.data.company.telephone);
          setEmail(response.data.company.email);
          setZipCode(response.data.company.addresses[0].zipcode);
          setState(response.data.company.addresses[0].state);
          setCity(response.data.company.addresses[0].city);
          setDistrict(response.data.company.addresses[0].district);
          setStreet(response.data.company.addresses[0].street);
          setNumber(response.data.company.addresses[0].number);
          setActive(response.data.company.active);
          setColor(response.data.company.color);
          setLogoUrl(response.data.company.logo_url);
          setLink(response.data.company.link);
          setCustomButton(response.data.company.custom_button);

          const options = {};
          options.label = response.data.company.owner?.name;
          options.value = response.data.company.owner?.id;

          setOwner(options);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Houve um erro, ao buscar os dados, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }
    loadCompany();
  }, [companyId, addToast, updateList]);

  const handleCreateCompany = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = new FormData();

        data.append('logo', logo);
        data.append('owner_id', owner.value);
        data.append('name', name);
        data.append('cnpj', cnpj);
        data.append('email', email);
        data.append('telephone', telephone);
        data.append('zipcode', zipCode);
        data.append('state', state);
        data.append('city', city);
        data.append('district', district);
        data.append('street', street);
        data.append('number', number);
        data.append('color', color);
        data.append('link', link);
        data.append('custom_button', customButton);

        await api.post('/companies', data);
        history.push('/companies/list');
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Empresa criada, com sucesso!',
        });
      } catch (error) {
        if (error.response.data.messages) {
          setErrorsField(error.response.data.messages);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Houve um erro ao cadastrar a empresa, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      addToast,
      city,
      cnpj,
      color,
      district,
      email,
      history,
      logo,
      name,
      number,
      state,
      street,
      telephone,
      zipCode,
      link,
      owner.value,
      customButton,
    ],
  );

  const handleUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = new FormData();

        data.append('logo', logo);
        data.append('name', name);
        data.append('cnpj', cnpj);
        data.append('email', email);
        data.append('telephone', telephone);
        data.append('zipcode', zipCode);
        data.append('state', state);
        data.append('city', city);
        data.append('district', district);
        data.append('street', street);
        data.append('number', number);
        data.append('color', color);
        data.append('link', link);
        data.append('custom_button', customButton);

        await api.put(`/companies/${companyId}`, data);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Empresa atualizada, com sucesso!',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Houve um erro ao atualizar a empresa, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      addToast,
      city,
      cnpj,
      color,
      district,
      email,
      logo,
      name,
      number,
      state,
      street,
      telephone,
      zipCode,
      link,
      companyId,
      customButton,
    ],
  );

  const handleStatusCompany = useCallback(async () => {
    try {
      await api.delete(`/companies/${companyId}`);
      addToast({
        title: 'Alerta',
        type: 'warning',
        description: 'Status alterado, com sucesso!',
      });
      setUpdateList(true);
    } catch (err) {
      addToast({
        title: 'Error',
        type: 'error',
        description:
          'Houve um erro ao alterar o status da empresa, tente novamente!',
      });
    }
  }, [companyId, addToast]);

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <form onSubmit={!isUpdate ? handleCreateCompany : handleUpdate}>
            <Grid container spacing={4} direction="row">
              <Grid item md={4} xs={12} sm={12}>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <Paper variant="outlined" style={{ padding: '30px' }}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            onChange={(event) => setLogo(event.target.files[0])}
                            type="file"
                          />

                          <label htmlFor="contained-button-file">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                            >
                              Adicionar Logo
                            </Button>
                          </label>

                          <div style={{ marginTop: '10px' }}>
                            {previewImg && (
                              <img
                                src={previewImg}
                                alt="Logo da empresa"
                                width="150"
                                style={{ borderRadius: '2px' }}
                              />
                            )}
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            {logoUrl && (
                              <img
                                src={logoUrl}
                                alt="Logo da empresa"
                                width="150"
                                style={{ borderRadius: '2px' }}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Button
                            onClick={() => setOpenColorPicker(!openColorPicker)}
                          >
                            Altera cor
                          </Button>
                          <div
                            style={{
                              position: 'relative',
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              backgroundColor: `${
                                color ? `${color}` : '#9c13aa'
                              }`,
                            }}
                          />
                          {openColorPicker && (
                            <SketchPicker
                              disableAlpha
                              color={color ? `${color}` : '#9c13aa'}
                              onChangeComplete={(value) => setColor(value.hex)}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={12}>
                      <Fab
                        fullWidth
                        variant="extended"
                        color="primary"
                        size="medium"
                        style={{ fontWeight: 'bold' }}
                        type="submit"
                      >
                        <FiPlusCircle
                          size={20}
                          style={{ marginRight: '10px' }}
                        />
                        {isUpdate ? 'Atualizar' : 'Adicionar'}
                      </Fab>
                      {user.role === 'admin' && (
                        <Fab
                          fullWidth
                          variant="extended"
                          size="medium"
                          className={`${
                            !active
                              ? `${classes.ButtonActive}`
                              : `${classes.buttonError}`
                          }`}
                          onClick={() => handleStatusCompany()}
                        >
                          {!active ? (
                            <FiCheckCircle
                              size={20}
                              style={{ marginRight: '10px' }}
                            />
                          ) : (
                            <FiXCircle
                              size={20}
                              style={{ marginRight: '10px' }}
                            />
                          )}
                          {!active ? 'Ativar' : 'Desativar'}
                        </Fab>
                      )}
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Grid item md={8}>
                <Paper variant="outlined" style={{ padding: '30px' }}>
                  <div style={{ padding: '10px' }}>
                    <Typography>Adicionar empresa</Typography>
                    <Divider />
                  </div>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Nome da empresa"
                        margin="normal"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={errorsField.find((data) => data.path === 'name')}
                      />
                    </Grid>
                    {user.role === 'admin' && (
                      <Grid item md={6} xs={12}>
                        <AutoComplete
                          options={ownersList}
                          value={owner}
                          disableClearable
                          onChange={(event, value) => setOwner(value)}
                          getOptionLabel={(option) => option.label}
                          style={{ width: '100%' }}
                          getOptionSelected={(option, value) => {
                            return option.label === value.label;
                          }}
                          noOptionsText="Responsável não encontrado"
                          renderInput={(params) => (
                            <TextField
                              autoComplete={false}
                              {...params}
                              fullWidth
                              variant="outlined"
                              label="Responsável pela empresa"
                              margin="normal"
                              error={errorsField.find(
                                (data) => data.path === 'owner_id',
                              )}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="CNPJ"
                        margin="normal"
                        value={cnpj}
                        onChange={(event) => setCnpj(event.target.value)}
                        error={errorsField.find((data) => data.path === 'cnpj')}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="E-mail"
                        margin="normal"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'email',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Telefone"
                        margin="normal"
                        value={telephone}
                        onChange={(event) => setTelephone(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'telephone',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="CEP"
                        margin="normal"
                        value={zipCode}
                        onChange={(event) => setZipCode(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'zipcode',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Estado"
                        margin="normal"
                        value={state}
                        onChange={(event) => setState(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'state',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Cidade"
                        margin="normal"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        error={errorsField.find((data) => data.path === 'city')}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Bairro"
                        margin="normal"
                        value={district}
                        onChange={(event) => setDistrict(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'district',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Endereço"
                        margin="normal"
                        value={street}
                        onChange={(event) => setStreet(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'street',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Número"
                        margin="normal"
                        value={number}
                        onChange={(event) => setNumber(event.target.value)}
                        error={errorsField.find(
                          (data) => data.path === 'number',
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Site da empresa(Opcional)"
                        placeholder="www.meusite.com.br"
                        margin="normal"
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        error={errorsField.find((data) => data.path === 'link')}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoComplete={false}
                        fullWidth
                        variant="outlined"
                        label="Botão para o cliente"
                        placeholder="Conheça nossa empresa"
                        margin="normal"
                        value={customButton}
                        onChange={(event) =>
                          setCustomButton(event.target.value)
                        }
                        error={errorsField.find((data) => data.path === 'link')}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Hidden smUp>
                  <div
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid item md={12}>
                      <Fab
                        fullWidth
                        variant="extended"
                        color="primary"
                        size="medium"
                        style={{ fontWeight: 'bold' }}
                        type="submit"
                      >
                        <FiPlusCircle
                          size={20}
                          style={{ marginRight: '10px' }}
                        />
                        {isUpdate ? 'Atualizar' : 'Adicionar'}
                      </Fab>
                      {user.role === 'admin' ||
                        (isUpdate && (
                          <Fab
                            fullWidth
                            variant="extended"
                            size="medium"
                            className={`${
                              !active
                                ? `${classes.ButtonActive}`
                                : `${classes.buttonError}`
                            }`}
                            onClick={() => handleStatusCompany()}
                          >
                            {!active ? (
                              <FiCheckCircle
                                size={20}
                                style={{ marginRight: '10px' }}
                              />
                            ) : (
                              <FiXCircle
                                size={20}
                                style={{ marginRight: '10px' }}
                              />
                            )}
                            {!active ? 'Ativar' : 'Desativar'}
                          </Fab>
                        ))}
                    </Grid>
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
