import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import {
  Container,
  Paper,
  List as ListMaterial,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemAvatar,
  Avatar,
  IconButton,
  makeStyles,
  Grid,
  Select,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core';

import { Pagination } from '@material-ui/lab';

import {
  FiDownload,
  FiEye,
  FiEyeOff,
  FiTrash2,
  FiInfo,
  FiMoreVertical,
} from 'react-icons/fi';

import { useCompany } from '../../hooks/companies';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function ListOfProducts(props) {
  const classes = useStyles();
  const { searchFilter, activeFilter, categoriesFilter } = props;
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [limitPage, setLimitPage] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [updateList, setUpdateList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();
  const { company } = useCompany();
  const { addToast } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    async function loadProducts() {
      try {
        setIsLoading(true);
        if (company) {
          const response = await api.get(
            `/products/${company.id}?page=${page}&pageSize=${pageSize}&search=${searchFilter}&active=${activeFilter}&categories=${categoriesFilter}`,
          );
          setProducts(response.data.products.rows);
          setCountPages(response.data.products.count);
          setLimitPage(response.data.limitPage);

          if (page > limitPage && limitPage !== 0) {
            setPage(limitPage);
          }
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um error os buscar os produtos, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }
    if (company) {
      loadProducts();
    }
  }, [
    addToast,
    company,
    searchFilter,
    activeFilter,
    categoriesFilter,
    page,
    pageSize,
    updateList,
    limitPage,
    countPages,
  ]);

  const handleDeactivateProduct = async (id) => {
    try {
      await api.delete(`/products/${id}`);
      setUpdateList(!updateList);
      setAnchorEl(null);
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Status do produto alterado com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Houve um error ao atualizar status, tente novamente!',
      });
    }
  };

  const handleDestroyProduct = async (id) => {
    try {
      await api.delete(`destroy/products/${id}`);
      setUpdateList(!updateList);
      setAnchorEl(null);
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Produto excluido com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Houve um error ao excluir produto, tente novamente!',
      });
    }
  };

  const handleDownload = async (qrcode) => {
    try {
      const response = await api.get(`/qrcode/${qrcode}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', qrcode);
      document.body.appendChild(link);
      link.click();
      setAnchorEl(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Houve um error ao realizar download, tente novamente!',
      });
    }
  };

  const handleGoToProduct = async (id) => {
    if (user.role !== 'user') {
      history.push(`/product/${id}`);
    }
  };
  return (
    <>
      <Container>
        {!isLoading ? (
          <ListMaterial>
            {products.map((product) => (
              <Paper
                variant="outlined"
                style={{ padding: '20px', marginBottom: '20px' }}
                key={product.id}
              >
                <ListItem
                  button={user.role !== 'user'}
                  onClick={() => handleGoToProduct(product.id)}
                >
                  <ListItemAvatar>
                    <Avatar alt={product.name} src={product.image_url} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={product.name}
                    secondary={product.active ? 'Ativado' : 'Desativado'}
                  />
                  <ListItemSecondaryAction>
                    <Hidden smDown>
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={() =>
                          history.push(`/details/${product.product_hash}`)
                        }
                        title="Informações do produto"
                      >
                        <FiInfo />
                      </IconButton>
                      {user.role !== 'user' && (
                        <>
                          <IconButton
                            edge="end"
                            color="secondary"
                            onClick={() => handleDownload(product.qrcode)}
                            title="Download QrCode"
                          >
                            <FiDownload />
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={() => handleDeactivateProduct(product.id)}
                            title={product.active ? 'Desativar' : 'Ativar'}
                          >
                            {product.active ? <FiEyeOff /> : <FiEye />}
                          </IconButton>
                          {!product.active && (
                            <>
                              <IconButton
                                edge="end"
                                title="Excluir produto"
                                onClick={() => handleDestroyProduct(product.id)}
                              >
                                <FiTrash2 />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                    </Hidden>
                    {user.role === 'user' ? (
                      <Hidden smUp>
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={() =>
                            history.push(`/details/${product.product_hash}`)
                          }
                          title="Informações do produto"
                        >
                          <FiInfo />
                        </IconButton>
                      </Hidden>
                    ) : (
                      <Hidden smUp>
                        <IconButton
                          edge="end"
                          color="secondary"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                          <FiMoreVertical />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          disableScrollLock
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() =>
                              history.push(`/details/${product.product_hash}`)
                            }
                          >
                            <FiInfo />
                            Informações do produto
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownload(product.qrcode)}
                          >
                            <FiDownload />
                            Download QrCode
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDeactivateProduct(product.id)}
                          >
                            {product.active ? <FiEyeOff /> : <FiEye />}
                            {product.active ? 'Desativar' : 'Ativar'}
                          </MenuItem>
                          {!product.active && (
                            <MenuItem
                              onClick={() => handleDestroyProduct(product.id)}
                            >
                              <FiTrash2 /> Excluir produto
                            </MenuItem>
                          )}
                        </Menu>
                      </Hidden>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </Paper>
            ))}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Pagination
                count={Math.ceil(countPages / pageSize)}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                color="primary"
              />
              <Select
                native
                value={pageSize}
                onChange={(event) => setPageSize(event.target.value)}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </Select>
            </Grid>
          </ListMaterial>
        ) : (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}
      </Container>
    </>
  );
}

ListOfProducts.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  categoriesFilter: PropTypes.string.isRequired,
  activeFilter: PropTypes.oneOf(['true', 'false', '']).isRequired,
};
