import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Fab,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
  Radio,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  makeStyles,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  FiPlusCircle,
  FiSearch,
  FiChevronDown,
  FiDownloadCloud,
} from 'react-icons/fi';

import { useCompany } from '../../../hooks/companies';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import ListOfProducts from '../../../components/ListProducts';

const useStyles = makeStyles(() => ({
  filterClean: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

export default function List() {
  const classes = useStyles();
  const [listCategories, setListCategories] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState('');
  const [categoriesFilterId, setCategoriesFilterId] = useState('');
  const [activeFilter, setActiveFilter] = useState('true');
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { company } = useCompany();
  const { addToast } = useToast();

  const handleResolveCategoryId = (event, value) => {
    setCategoriesFilter(value);
    if (value) {
      setCategoriesFilterId(value.id);
    } else {
      setCategoriesFilterId('');
    }
  };

  const clearFilter = () => {
    setSearchFilter('');
    setCategoriesFilter('');
    setCategoriesFilterId('');
    setActiveFilter('');
  };

  useEffect(() => {
    async function loadCategories() {
      setIsLoading(true);
      try {
        if (company) {
          const response = await api.get(`/categories/filter/${company.id}`);
          setListCategories(response.data.categories);
        }
      } catch {
        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            'Houve um error os buscar as categorias, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }
    loadCategories();
  }, [company, addToast]);

  return (
    <Container>
      {!isLoading || company ? (
        <>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            style={{ fontWeight: 'bold', marginLeft: '20px' }}
            onClick={() => history.push('/product')}
          >
            <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
            Novo Produto
          </Fab>
          <Grid container style={{ marginTop: '10px' }}>
            <Hidden smUp>
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                    <Typography>Filtros</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      spacing={5}
                    >
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Procurar"
                          value={searchFilter}
                          onChange={(event) =>
                            setSearchFilter(event.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiSearch />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={listCategories}
                          getOptionLabel={(option) => option.name}
                          value={categoriesFilter}
                          onChange={handleResolveCategoryId}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Categorias"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>Status</FormLabel>
                          <RadioGroup
                            value={activeFilter}
                            onChange={(event) =>
                              setActiveFilter(event.target.value)
                            }
                          >
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="Ativados"
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="Desativados"
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value=""
                                  control={<Radio />}
                                  label="Ambos"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Hidden>
            <Grid item xs>
              <ListOfProducts
                searchFilter={searchFilter}
                activeFilter={activeFilter}
                categoriesFilter={categoriesFilterId}
                onApllyFilter
              />
            </Grid>
            <Hidden smDown>
              <Grid item xs={3}>
                <Card>
                  <CardHeader title="Filtros" />
                  <CardContent>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      spacing={5}
                    >
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Procurar"
                          value={searchFilter}
                          onChange={(event) =>
                            setSearchFilter(event.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiSearch />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={listCategories}
                          getOptionLabel={(option) => option.name}
                          value={categoriesFilter}
                          onChange={handleResolveCategoryId}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Categorias"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>Status</FormLabel>
                          <RadioGroup
                            value={activeFilter}
                            onChange={(event) =>
                              setActiveFilter(event.target.value)
                            }
                          >
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="Ativados"
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="Desativados"
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value=""
                                  control={<Radio />}
                                  label="Ambos"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActionArea className={classes.filterClean}>
                    <Button color="primary" onClick={clearFilter}>
                      Limpar filtros
                    </Button>
                  </CardActionArea>
                </Card>
              </Grid>
            </Hidden>
          </Grid>
        </>
      ) : (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      )}
    </Container>
  );
}
