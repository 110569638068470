import React from "react";

import { Container, Paragraph, Content, Title } from "./styles";

export default function PrivacyMobile() {
  return (
    <Container>
      <Content>
        <h1>Política de privacidade do Olha.ai</h1>
        <Title>SEÇÃO 1</Title>
        <Paragraph>
          NÃO ARMAZENAMOS OS DADOS Nenhum dado será armazenado do
          cliente, todos os dados ficam em seu celular e no histórico do
          navegador de sua preferência, o app serve única e exclusivamente como
          interface para abrir o QrCode.
        </Paragraph>
        <Title>SEÇÃO 2</Title>
        <Paragraph>
          SEÇÃO 2 – CONSENTIMENTO PARA USO DE CÂMERA É necessário a permissão
          para uso de câmera para que seja possível a leitura do QrCode. Não
          sendo utilizado para nenhum outro proposito.
        </Paragraph>
        <Title>SEÇÃO 3</Title>
        <Paragraph>
          SEÇÃO 3 - DIVULGAÇÃO Não divulgaremos suas informações pessoais em
          nenhuma hipótese.
        </Paragraph>
        <Title>SEÇÃO 4</Title>
        <Paragraph>
          SEÇÃO 4 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE Reservamos o
          direito de modificar essa política de privacidade a qualquer momento,
          então por favor, revise-a com frequência. Alterações e esclarecimentos
          vão surtir efeito imediatamente após sua publicação no site. Se
          fizermos alterações de materiais para essa política, iremos
          notificá-lo aqui que eles foram atualizados, para que você tenha
          ciência sobre quais informações coletamos, como as usamos, e sob que
          circunstâncias, se alguma, usamos e/ou divulgamos elas. Se nossa loja
          for adquirida ou fundida com outra empresa, suas informações podem ser
          transferidas para os novos proprietários para que possamos continuar a
          fornecer nosso produto para você.
        </Paragraph>
      </Content>
    </Container>
  );
}
