import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiPlayCircle, FiPauseCircle } from 'react-icons/fi';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

export default function AudioPlayer({ audioUrl }) {
  const styles = useStyles();

  const [audioPlayer, setAudioPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    function initAudioPlayer() {
      setAudioPlayer(new Audio(audioUrl));
    }

    initAudioPlayer();
  }, [audioUrl]);

  const handlePlay = async () => {
    setIsPlaying(true);
    if (audioPlayer.paused || audioPlayer.ended) {
      audioPlayer.play();
    }
  };

  const handlePause = async () => {
    setIsPlaying(false);
    audioPlayer.pause();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={!isPlaying ? handlePlay : handlePause}
        className={styles.button}
      >
        {!isPlaying ? (
          <FiPlayCircle size="25" style={{ marginRight: '10px' }} />
        ) : (
          <FiPauseCircle size="25" style={{ marginRight: '10px' }} />
        )}
        Ouvir sobre o produto
      </Button>
    </div>
  );
}

AudioPlayer.propTypes = {
  audioUrl: PropTypes.string.isRequired,
};
