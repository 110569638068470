import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  Grid,
  Slide,
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  DialogContent,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';

import AudioPlayer from '../../../components/AudioPlayer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));
export default function DialogMedia({
  open,
  onClose,
  audioUrl,
  videoUrl,
  color,
  logo,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          elevation={0}
          style={{ backgroundColor: `${color}` }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <FiXCircle />
            </IconButton>
            <img src={logo} alt="Logo da empresa" width="80px" />
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className={classes.root} elevation={2}>
                <CardMedia>
                  <ReactPlayer
                    className="player"
                    url={videoUrl}
                    controls
                    width="100%"
                  />
                </CardMedia>
                <CardContent className={classes.content}>
                  <AudioPlayer audioUrl={audioUrl} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

DialogMedia.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  audioUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};
