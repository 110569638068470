import React, { useState, useEffect, useCallback } from 'react';
import { shade } from 'polished';
import {
  Container,
  Paper,
  List as ListMaterial,
  ListItem,
  ListItemText,
  Fab,
  CircularProgress,
  makeStyles,
  Typography,
  ListItemSecondaryAction,
  Button,
  Grid,
  Select,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FiPlusCircle, FiCheckCircle, FiXCircle } from 'react-icons/fi';

import api from '../../../services/api';
import { useCompany } from '../../../hooks/companies';
import { useToast } from '../../../hooks/toast';

import Form from '../Form';

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: `${palette.success.main}`,
    fontWeight: 'bold',
  },
  disabled: {
    color: `${palette.error.main}`,
    fontWeight: 'bold',
  },

  ButtonActive: {
    backgroundColor: palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.success.main)} !important`,
      color: '#fff',
    },
  },
  ButtonDisabled: {
    backgroundColor: palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.error.main)} !important`,
      color: '#fff',
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function List() {
  const classes = useStyles();
  const { company } = useCompany();
  const { addToast } = useToast();

  const [updateList, setUpdateList] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [listCategories, setListCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateList, setIsUpdateList] = useState(false);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get(
          `/categories/${company.id}?page=${page}&pageSize=${pageSize}`,
        );
        setListCategories(response.data.categories.rows);
        setCountPages(response.data.categories.count);
      } catch (err) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Houve um erro ao carregar a lista, tente novamente!',
        });
      } finally {
        setIsLoading(false);
        setUpdateList(false);
      }
    }

    loadCategories();
  }, [company, updateList, page, pageSize, addToast, isUpdateList]);

  const toogleDialog = useCallback(() => {
    setOpenDialog(!openDialog);
    setIdEdit('');
    setIsUpdateList(!isUpdateList);
  }, [openDialog, isUpdateList]);

  const toogleDialogEdit = useCallback(
    (id) => {
      setOpenDialog(!openDialog);
      setIdEdit(id);
      setIsUpdateList(!isUpdateList);
    },
    [openDialog, isUpdateList],
  );

  const handleStatusCategory = useCallback(
    async (id) => {
      try {
        await api.delete(`/categories/${id}`);
        addToast({
          title: 'Alerta',
          type: 'warning',
          description: 'Status alterado, com sucesso!',
        });
        setUpdateList(true);
      } catch (err) {
        addToast({
          title: 'Error',
          type: 'error',
          description:
            'Houve um erro ao alterar o status da categoria, tente novamente!',
        });
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Fab
              variant="extended"
              size="large"
              color="primary"
              aria-label="add"
              style={{ fontWeight: 'bold' }}
              onClick={toogleDialog}
            >
              <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
              Nova Categoria
            </Fab>
            <Form
              open={openDialog}
              onToggleDialog={toogleDialog}
              idEdit={idEdit}
            />
            <ListMaterial>
              {listCategories.map((category) => (
                <Paper
                  variant="outlined"
                  style={{ padding: '20px', marginBottom: '20px' }}
                  key={category.id}
                >
                  <ListItem
                    button
                    onClick={() => toogleDialogEdit(category.id)}
                  >
                    <ListItemText
                      primary={category.name}
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={`${
                              category.active
                                ? `${classes.active}`
                                : `${classes.disabled}`
                            }`}
                          >
                            {category.active ? 'Ativado' : 'Desativado'}
                          </Typography>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Button
                        variant="contained"
                        size="small"
                        className={`${
                          !category.active
                            ? `${classes.ButtonActive}`
                            : `${classes.ButtonDisabled}`
                        }`}
                        aria-label="add"
                        onClick={() => handleStatusCategory(category.id)}
                      >
                        {!category.active ? (
                          <FiCheckCircle
                            size={20}
                            style={{ marginRight: '10px' }}
                          />
                        ) : (
                          <FiXCircle
                            size={20}
                            style={{ marginRight: '10px' }}
                          />
                        )}
                        {!category.active ? 'Ativar' : 'Desativar'}
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Pagination
                  count={Math.ceil(countPages / pageSize)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                  color="primary"
                />
                <Select
                  native
                  value={pageSize}
                  onChange={(event) => setPageSize(event.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                </Select>
              </Grid>
            </ListMaterial>
          </>
        )}
      </Container>
    </>
  );
}
