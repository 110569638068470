import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Drawer,
  List,
  Divider,
  makeStyles,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { FiHome, FiShoppingBag, FiFolder, FiUsers } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles({
  list: {
    width: 300,
    marginTop: '50px',
  },
  listItem: {
    padding: '20px 20px',
  },
  listLink: {
    color: '#000',
  },
});

const listNav = [
  {
    id: 1,
    name: 'Produtos',
    link: '/dashboard',
    icon: <FiShoppingBag size={22} color="#000" />,
    roles: ['user', 'owner', 'admin'],
  },
  {
    id: 2,
    name: 'Empresas',
    link: '/companies/list',
    icon: <FiHome size={22} color="#000" />,
    roles: ['owner', 'admin'],
  },
  {
    id: 3,
    name: 'Categorias',
    link: '/list/categories',
    icon: <FiFolder size={22} color="#000" />,
    roles: ['owner', 'admin'],
  },
  {
    id: 4,
    name: 'Usuários',
    link: '/user/list',
    icon: <FiUsers size={22} color="#000" />,
    roles: ['owner', 'admin'],
  },
];
export default function Sidenav({ open, onToggleNav }) {
  const classes = useStyles();

  const { user } = useAuth();

  return (
    <nav>
      <Drawer disableScrollLock open={open} onClose={() => onToggleNav()}>
        <div className={classes.list}>
          <List>
            {listNav.map((item) => (
              <div key={item.id}>
                {item.roles.map(
                  (role) =>
                    user.role === role && (
                      <>
                        <Link to={item.link} className={classes.listLink}>
                          <ListItem button className={classes.listItem}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        </Link>
                        <Divider />
                      </>
                    ),
                )}
              </div>
            ))}
          </List>
        </div>
      </Drawer>
    </nav>
  );
}

Sidenav.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleNav: PropTypes.func.isRequired,
};
