import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Slide,
} from '@material-ui/core';
import { FiStar, FiXCircle, FiCode } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noContent: {
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListRating({ id, onClose, open }) {
  const classes = useStyles();
  const { addToast } = useToast();
  const [listProductRating, setListProductRating] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadProductRating() {
      try {
        const response = await api.get(`/rating/${id}`);
        setListProductRating(response.data.response.rows);
        setAvgRating(response.data.avgRating);
      } catch (error) {
        addToast({
          title: 'Erro!',
          description:
            'Houve um erro ao tentar buscar as avaliações, tente novamente!',
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadProductRating();
  }, [addToast, id]);
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: 'relative' }} elevation={0} color="inherit">
        <Toolbar>
          <IconButton
            name="Fechar video e audio"
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => onClose()}
          >
            <FiXCircle />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          <div style={{ marginLeft: '20px' }}>
            Avaliações
            <Typography color="textSecondary" variant="body2">
              Total: {listProductRating.length || '0'} Média: {avgRating || '0'}
            </Typography>
          </div>
        </Toolbar>
        <Divider />
      </AppBar>
      <DialogContent>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {listProductRating.length <= 0 ? (
              <div className={classes.noContent}>
                <FiCode size={22} />
                <Typography variant="body2" color="textSecondary">
                  Ninguém avaliou esse produto!
                </Typography>
              </div>
            ) : (
              <List className={classes.root}>
                {listProductRating.map((productRating) => (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <div>
                              <FiStar size="20" color="#ffb400" /> {' - '}{' '}
                              {productRating.rating}
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {productRating.name || 'Anonimo'}
                            </Typography>
                            {` - ${
                              productRating.description ||
                              'Avalição sem comentário'
                            }`}
                          </>
                        }
                      />
                    </ListItem>
                  </>
                ))}
              </List>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

ListRating.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
