import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Fab,
  TextField,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import { useCompany } from '../../../hooks/companies';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export default function Form({ open, onToggleDialog, idEdit }) {
  const classes = useStyles();
  const { company } = useCompany();
  const { addToast } = useToast();

  const [name, setName] = useState('');
  const [errorsField, setErrorsField] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadEditCategory() {
      setIsLoading(true);
      try {
        const response = await api.get(`/categories/show/${idEdit}`);
        setName(response.data.category.name);
      } catch (err) {
        addToast({
          type: 'Error',
          title: 'Erro',
          description: 'Houve um erro ao buscar a categoria, tente novamente !',
        });
      } finally {
        setIsLoading(false);
      }
    }
    if (idEdit) {
      loadEditCategory();
    }
  }, [idEdit, addToast]);

  const handleCreateCategory = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        await api.post('/categories', {
          name,
          company_id: company.id,
        });

        onToggleDialog();
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Categoria criada com sucesso!',
        });
      } catch (error) {
        if (error.response.data.messages) {
          setErrorsField(error.response.data.messages);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Houve um erro ao adicionar a categoria, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [name, company, addToast, onToggleDialog],
  );

  const handleUpdateCategory = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await api.put(`/categories/${idEdit}`, { name });
        onToggleDialog();
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Categoria atualizada com sucesso!',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Houve um erro ao atualizar a categoria, tente novamente!',
        });
      }
    },
    [name, addToast, onToggleDialog, idEdit],
  );

  return (
    <>
      <Dialog disableScrollLock open={open} onClose={onToggleDialog}>
        <DialogTitle>Adicionar Categoria</DialogTitle>
        <form onSubmit={!idEdit ? handleCreateCategory : handleUpdateCategory}>
          <>
            <DialogContent>
              {isLoading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <TextField
                        autoFocus
                        autoComplete={false}
                        margin="dense"
                        id="name"
                        label="Categoria"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        variant="outlined"
                        error={errorsField.find((data) => data.path === 'name')}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Fab
                variant="extended"
                size="large"
                color="primary"
                aria-label="add"
                style={{ fontWeight: 'bold' }}
                type="submit"
                disabled={isLoading}
              >
                {idEdit ? 'Atualizar' : 'Adicionar'}
              </Fab>
            </DialogActions>
          </>
        </form>
      </Dialog>
    </>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
  idEdit: PropTypes.string,
};

Form.defaultProps = {
  idEdit: '',
};
