import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';

import defaultLogoImg from '../../../assets/logo-miru-black.png';

export default function HeaderDetails({ color, logoImg }) {
  const history = useHistory();
  return (
    <>
      <AppBar
        position="fixed"
        style={{ backgroundColor: `${color}` }}
        elevation={0}
      >
        <Toolbar>
          <IconButton color="inherit" onClick={() => history.goBack()}>
            <FiArrowLeftCircle />
          </IconButton>
          <img
            src={logoImg || defaultLogoImg}
            alt="Logo da empresa"
            width="80px"
          />
        </Toolbar>
      </AppBar>
    </>
  );
}

HeaderDetails.propTypes = {
  color: PropTypes.string.isRequired,
  logoImg: PropTypes.string.isRequired,
};
