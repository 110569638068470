import React, { useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { shade } from 'polished';
import {
  Container,
  Paper,
  List as ListMaterial,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FiPlusCircle, FiXCircle, FiCheckCircle } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: `${palette.success.main}`,
    fontWeight: 'bold',
  },
  disabled: {
    color: `${palette.error.main}`,
    fontWeight: 'bold',
  },
  ButtonActive: {
    backgroundColor: palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.success.main)} !important`,
      color: '#fff',
    },
  },
  ButtonDisabled: {
    backgroundColor: palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.error.main)} !important`,
      color: '#fff',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function List() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToast();
  const { productId } = useParams();

  const [listCoupons, setListCoupons] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [pageSize, setpageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Dica!',
      description: 'Apenas um cupom ativo por produto!',
    });
  }, [addToast]);

  useEffect(() => {
    async function loadCoupons() {
      try {
        setUpdateList(false);
        const response = await api.get(
          `/cuopons/${productId}?page=${page}&pageSize=${pageSize}`,
        );
        setListCoupons(response.data.response.rows);
        setCountPages(response.data.response.count);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao carregar os cupons, tente novamtente',
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadCoupons();
  }, [addToast, productId, updateList, page, pageSize]);

  const handleStatusCoupon = async (id) => {
    setIsLoading(true);
    try {
      await api.delete(`/cuopons/${id}`);
      addToast({
        type: 'warning',
        title: 'Alerta!',
        description: 'Status atualizado com sucesso!',
      });
      setUpdateList(true);
    } catch (err) {
      addToast({
        type: 'info',
        title: 'Erro!',
        description: 'Houve um erro ao atualizar o cupom, tente novamtente',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <Fab
              variant="extended"
              size="large"
              color="primary"
              aria-label="add"
              style={{ fontWeight: 'bold' }}
              onClick={() => history.push('/coupon/form', { productId })}
            >
              <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
              Novo cupom
            </Fab>
            <ListMaterial>
              {listCoupons.map((item) => (
                <div key={item.id}>
                  <Paper
                    variant="outlined"
                    style={{ padding: '20px', marginBottom: '20px' }}
                  >
                    <ListItem
                      button
                      onClick={() => history.push(`/coupon/form/${item.id}`)}
                    >
                      <ListItemText
                        primary={item.cuopon}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${
                                item.active
                                  ? `${classes.active}`
                                  : `${classes.disabled}`
                              }`}
                            >
                              {item.active ? 'Ativado' : 'Desativado'}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant="extended"
                          size="small"
                          className={`${
                            !item.active
                              ? `${classes.ButtonActive}`
                              : `${classes.ButtonDisabled}`
                          }`}
                          aria-label="add"
                          onClick={() => handleStatusCoupon(item.id)}
                        >
                          {!item.active ? (
                            <FiCheckCircle
                              size={20}
                              style={{ marginRight: '10px' }}
                            />
                          ) : (
                            <FiXCircle
                              size={20}
                              style={{ marginRight: '10px' }}
                            />
                          )}
                          {!item.active ? 'Ativar' : 'Desativar'}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Paper>
                </div>
              ))}
              <Pagination
                count={Math.ceil(countPages / pageSize)}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                color="primary"
              />
            </ListMaterial>
          </>
        )}
      </Container>
    </>
  );
}

export default withRouter(List);
