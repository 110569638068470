import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shade } from 'polished';
import {
  Container,
  Paper,
  List as ListMaterial,
  ListItem,
  ListItemText,
  Fab,
  CircularProgress,
  makeStyles,
  Typography,
  Grid,
  Select,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FiPlusCircle } from 'react-icons/fi';

import { useCompany } from '../../../hooks/companies';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: `${palette.success.main}`,
    fontWeight: 'bold',
  },
  disabled: {
    color: `${palette.error.main}`,
    fontWeight: 'bold',
  },

  ButtonActive: {
    backgroundColor: palette.success.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.success.main)} !important`,
      color: '#fff',
    },
  },
  ButtonDisabled: {
    backgroundColor: palette.error.main,
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${shade(0.2, palette.error.main)} !important`,
      color: '#fff',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alingContent: 'center',
  },
}));

export default function List() {
  const classes = useStyles();
  const { company } = useCompany();
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    async function loadUsers() {
      try {
        if (!company && user.role === 'admin') {
          const response = await api.get(
            `/users?page=${page}&pageSize=${pageSize}`,
          );
          setUsers(response.data.users.rows);
          setCountPages(response.data.users.count);
        } else {
          const response = await api.get(
            `/users/${company.id}?page=${page}&pageSize=${pageSize}`,
          );
          setUsers(response.data.users.rows);
          setCountPages(response.data.users.count);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um error os buscar os usuários, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadUsers();
  }, [company, page, pageSize]);

  return (
    <Container>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            style={{ fontWeight: 'bold' }}
            onClick={() => history.push('/users')}
          >
            <FiPlusCircle size={20} style={{ marginRight: '10px' }} />
            Novo usuário
          </Fab>
          <ListMaterial>
            {users.map((item) => (
              <Paper
                variant="outlined"
                style={{ padding: '20px', marginBottom: '20px' }}
                key={item.id}
              >
                <ListItem
                  button
                  onClick={() => history.push(`/users/${item.id}`)}
                >
                  <ListItemText
                    primary={`${item.name} ${
                      item.id === user.id ? ' - (Eu)' : ''
                    } ${
                      user.role === 'admin'
                        ? `| Empresa(s): ${item.companies.map(
                            (CompanyItem) => CompanyItem.name,
                          )}`
                        : ''
                    }`}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={`${
                            item.active
                              ? `${classes.active}`
                              : `${classes.disabled}`
                          }`}
                        >
                          {item.active ? 'Ativado' : 'Desativado'}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </Paper>
            ))}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Pagination
                count={Math.ceil(countPages / pageSize)}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                color="primary"
              />
              <Select
                native
                value={pageSize}
                onChange={(event) => setPageSize(event.target.value)}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </Select>
            </Grid>
          </ListMaterial>
        </>
      )}
    </Container>
  );
}
