import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const CompaniesContext = createContext({});

function CompaniesProvider({ children }) {
  const [data, setData] = useState(() => {
    const company = localStorage.getItem('@Miru:company');

    if (company) {
      return { company: JSON.parse(company), selectedCompany: true };
    }
    return {};
  });

  const addCompany = useCallback((company) => {
    localStorage.setItem('@Miru:company', JSON.stringify(company));
    setData({ company, selectedCompany: true });
  }, []);

  const removeCompany = useCallback(() => {
    localStorage.removeItem('@Miru:company');
    setData({});
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        addCompany,
        removeCompany,
        company: data.company,
        selectedCompany: data.selectedCompany,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
}

function useCompany() {
  const context = useContext(CompaniesContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

CompaniesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { useCompany, CompaniesProvider };
