import React, { useState, useEffect, useCallback } from 'react';
import cx from 'clsx';
import { useParams } from 'react-router-dom';
import {
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import HeaderDetails from './HeaderDetails';
import DialogMedia from './DialogMedia';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    height: '450px',
    maxHeight: '100%',
    borderRadius: 6,
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'justify',
    marginTop: '10px',
    color: '#333',
    marginBottom: '15px',
    fontSize: '15px',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  loading: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function DetailsEmployee() {
  const styles = useStyles();
  const { id } = useParams();
  const { addToast } = useToast();

  const mediaStyles = useFourThreeCardMediaStyles();
  const shadowStyles = useOverShadowStyles({ inactive: true });

  const [openDialogMedia, setOpenDialogMedia] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [product, setProduct] = useState([]);

  const [color, setColor] = useState('');
  const [logo, setLogo] = useState('');

  useEffect(() => {
    async function loadProduct() {
      try {
        const response = await api.get(`/info/${id}`);
        setProduct(response.data.product);
        setColor(response.data.product.company.color);
        setLogo(response.data.product.company.logo_url);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Houve um erro ao carregar o produto, tente novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadProduct();
  }, [id, addToast]);

  const handleToggleDialogMedia = useCallback(() => {
    setOpenDialogMedia(!openDialogMedia);
  }, [openDialogMedia]);

  return (
    <div>
      {isLoading ? (
        <div className={styles.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <HeaderDetails color={color} logoImg={logo} />
          <Container>
            <div style={{ marginTop: '100px' }}>
              <Card className={cx(styles.root, shadowStyles.root)}>
                <CardMedia
                  className={cx(styles.media, mediaStyles.root)}
                  image={product.image_url}
                />
                <CardContent className={styles.content}>
                  <Typography className={styles.title}>
                    {product.name}
                  </Typography>
                  <Divider />
                  <Typography className={styles.description}>
                    {product.description}
                  </Typography>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        name="Descubra mais do produto"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        className={styles.button}
                        onClick={handleToggleDialogMedia}
                      >
                        Descubra mais do produto
                      </Button>
                    </Grid>
                    <DialogMedia
                      open={openDialogMedia}
                      onClose={handleToggleDialogMedia}
                      audioUrl={product.fun_audio_url}
                      videoUrl={product.video}
                      color={color}
                      logo={logo}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Container>
        </>
      )}
    </div>
  );
}
