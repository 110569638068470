import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Avatar,
  Divider,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { FiHome, FiPlusCircle } from 'react-icons/fi';

import { useCompany } from '../../hooks/companies';
import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: deepPurple[100],
    color: deepPurple[600],
  },
});

export default function DialogCompanies({ open, onToggleDialog }) {
  const classes = useStyles();
  const history = useHistory();
  const { addCompany, company } = useCompany();
  const { user } = useAuth();

  const handleGoCompany = () => {
    history.push('/company');
    onToggleDialog();
  };

  const handleGoListCompany = () => {
    history.push('/companies/list');
    onToggleDialog();
  };
  return (
    <>
      <Dialog open={open} onClose={onToggleDialog}>
        <DialogTitle>
          {company ? company.name : 'Selecionar Empresa'}
        </DialogTitle>
        <DialogContent>
          <List>
            {user.role === 'admin' ? (
              <DialogContentText>
                Olá admin, para continuar acesse a lista das empresas.
              </DialogContentText>
            ) : (
              <>
                {user.companies.length === 0 ? (
                  <DialogContentText>
                    Não encontramos a sua empresa, você pode começar cadastrando
                    ela clicando no botão abaixo
                  </DialogContentText>
                ) : (
                  <>
                    {user.companies.map(
                      (item) =>
                        item.active && (
                          <ListItem
                            button
                            disabled={company ? company.id === item.id : false}
                            key={item.id}
                            onClick={() => addCompany(item)}
                          >
                            <ListItemAvatar>
                              <Avatar className={classes.avatar}>
                                <FiHome />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        ),
                    )}
                  </>
                )}
              </>
            )}
            <Divider />
            {user.role === 'admin' ? (
              <ListItem button onClick={handleGoListCompany}>
                <ListItemAvatar>
                  <Avatar>
                    <FiHome />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Lista das empresas" />
              </ListItem>
            ) : (
              <>
                {user.role !== 'user' && (
                  <ListItem button onClick={handleGoCompany}>
                    <ListItemAvatar>
                      <Avatar>
                        <FiPlusCircle />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Adicionar empresa" />
                  </ListItem>
                )}
              </>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

DialogCompanies.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
};
