import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 430px;
  width: 95%;
  margin-top: 50px;
  border-radius: 6px;
  padding: 55px;

  p {
    max-width: 100%;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    color: #fff;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  max-width: 200px;
  margin-bottom: 25px;
`;
