import styled from "styled-components";

export const Container = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 20px 50px;
`;

export const Content = styled.div`
  margin-top: 65px;
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Paragraph = styled.p`
  color: #000;
  font-size: 15px;
  text-align: justify;
  margin:14px 0 14px 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

export const Title = styled.h2`
  color: #000;
  font-size: 18px;
`;